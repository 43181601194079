/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    h1: "h1",
    a: "a",
    div: "div",
    p: "p",
    h2: "h2",
    ol: "ol",
    li: "li",
    strong: "strong",
    pre: "pre",
    code: "code",
    h3: "h3",
    ul: "ul",
    h4: "h4",
    hr: "hr"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.h1, {
    id: "overview",
    style: {
      position: "relative"
    }
  }, "Overview", React.createElement(_components.a, {
    href: "#overview",
    "aria-label": "overview permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most pages in the application have a repeatable pattern of components. This documentation provides the general best practice for what can be added to a header, where in it, and when to use it. This pattern is used in conjunction with our ", React.createElement(_components.a, {
    href: "/components/page/"
  }, "Page"), " component."), "\n", React.createElement(_components.h2, {
    id: "principles",
    style: {
      position: "relative"
    }
  }, "Principles", React.createElement(_components.a, {
    href: "#principles",
    "aria-label": "principles permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ol, null, "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Consistency in structure and page relation."), " On any given page, how the header arranges descriptions, actions, and navigation should be similar across the application."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Prioritize small vertical height."), " Many pages in the ServiceTitan app are dense. Headers should help users complete their tasks on a page, but should get out of the way for the page’s main content."), "\n"), "\n", React.createElement(_components.li, null, "\n", React.createElement(_components.p, null, React.createElement(_components.strong, null, "Consistency in foundational styles."), " There are many ways to subtly visualize the same header. Standardizing the foundational choices, from text sizes to button variations, improves familiarity to users."), "\n"), "\n"), "\n", React.createElement(_components.h1, {
    id: "header-anatomy",
    style: {
      position: "relative"
    }
  }, "Header Anatomy", React.createElement(_components.a, {
    href: "#header-anatomy",
    "aria-label": "header anatomy permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Page Headers are broken up into three general functions: The description of the page, actions on the page, and navigation within the page."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\nscaled: true\n---\nconst Example = () => {\n\tconst breadcrumbs = (\n\t\t<Breadcrumb className=\"m-b-1\">\n\t\t\t<Breadcrumb.Link label=\"Root page\" />\n\t\t\t<Breadcrumb.Link label=\"Subpage\" />\n\t\t\t<Breadcrumb.Link label=\"Current page\" />\n\t\t</Breadcrumb>\n\t);\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t\t<Tag>Tag</Tag>\n\t\t</Stack>\n\t);\n\tconst actions = (\n\t\t<ButtonGroup>\n\t\t\t<Button small>Action</Button>\n\t\t\t<Button primary small>Primary Action</Button>\n\t\t</ButtonGroup>\n\t);\n\tconst description = (\n\t\t<BodyText subdued className=\"m-t-1\">A standard description for the entire page.</BodyText>\n\t);\n\n\treturn (\n\t\t<Frame\n\t\t\t/* Frame's header prop is a visual demo. */\n\t\t\theader={\n\t\t\t\t<div style={{ height: 56 }}>\n\t\t\t\t\t<div style={{ backgroundColor: \"#141414\", height: 56, position: \"fixed\", top: 0, width: \"100%\", zIndex: 1 }} />\n\t\t\t\t</div>\n\t\t\t}\n\t\t>\n\t\t\t<Page\n\t\t\t\theader={\n\t\t\t\t\t<>\n\t\t\t\t\t\t{breadcrumbs}\n\t\t\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t\t\t{title('Page Title')}\n\t\t\t\t\t\t\t</Stack.Item>\n\t\t\t\t\t\t\t{actions}\n\t\t\t\t\t\t</Stack>\n\t\t\t\t\t\t{description}\n\t\t\t\t\t\t<TabGroup className=\"m-t-3\">\n\t\t\t\t\t\t\t<Tab active>Active Tab</Tab>\n\t\t\t\t\t\t\t<Tab>Action Required</Tab>\n\t\t\t\t\t\t\t<Tab>Insights</Tab>\n\t\t\t\t\t\t\t<Tab>Settings</Tab>\n\t\t\t\t\t\t</TabGroup>\n\t\t\t\t\t</>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\t<Banner\n\t\t\t\t\ticon\n\t\t\t\t\ttitle=\"You are viewing an inactive item.\"\n\t\t\t\t\tclassName=\"m-b-4\"\n\t\t\t\t/>\n\t\t\t\tContent of the page.\n\t\t\t</Page>\n\t\t</Frame>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h2, {
    id: "metadata",
    style: {
      position: "relative"
    }
  }, "Metadata", React.createElement(_components.a, {
    href: "#metadata",
    "aria-label": "metadata permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most pages will provide a basic amount of information in the header, typically just a page title."), "\n", React.createElement(_components.h3, {
    id: "page-title",
    style: {
      position: "relative"
    }
  }, "Page title", React.createElement(_components.a, {
    href: "#page-title",
    "aria-label": "page title permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most pages should have a title. The title should represent the main content of the page itself, including user-generated titles."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Page header={\n\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t<Stack.Item fill>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">Page Title</Headline>\n\t\t</Stack.Item>\n\t</Stack>\n}>\n\t<Divider spacing=\"4\" />\n\tContent of the page.\n</Page>\n")), "\n", React.createElement(_components.h3, {
    id: "description",
    style: {
      position: "relative"
    }
  }, "Description", React.createElement(_components.a, {
    href: "#description",
    "aria-label": "description permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Descriptions provide a more detailed overview of what the page is about. In general this will be a single line of text, but can be customized to fit the specific needs of a page. A title should always be used with a description."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst description = (\n\t\t<BodyText subdued className=\"m-t-1\">Edit requisitions in the table before you confirm and save.</BodyText>\n\t);\n\n\treturn (\n\t\t<Page\n\t\t\theader={\n\t\t\t\t<>\n\t\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t\t{title('Requisition #1234567')}\n\t\t\t\t\t\t</Stack.Item>\n\t\t\t\t\t</Stack>\n\t\t\t\t\t{description}\n\t\t\t\t</>\n\t\t\t}\n\t\t>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst description = (\n\t\t<Grid columns={3} className=\"m-t-3\">\n\t\t\t<Grid.Column>\n\t\t\t\t<Eyebrow >Category</Eyebrow>\n\t\t\t\t<Headline className='m-t-half m-b-0 t-truncate' size='small'>Referrals – Widgets</Headline>\n\t\t\t</Grid.Column>\n\t\t\t<Grid.Column>\n\t\t\t\t<Eyebrow>Business Unit</Eyebrow>\n\t\t\t\t<Headline className='m-t-half m-b-0 t-truncate' size='small'>Change-Out</Headline>\n\t\t\t</Grid.Column>\n\t\t\t<Grid.Column>\n\t\t\t\t<Eyebrow>Advertised Number</Eyebrow>\n\t\t\t\t<Headline className='m-t-half m-b-0 t-truncate' size='small'>(123) 456–7890</Headline>\n\t\t\t</Grid.Column>\n\t\t</Grid>\n\t);\n\n\treturn (\n\t\t<Page header={\n\t\t\t<>\n\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t{title('Campaign Promo')}\n\t\t\t\t\t</Stack.Item>\n\t\t\t\t</Stack>\n\t\t\t\t{description}\n\t\t\t</>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h3, {
    id: "tags",
    style: {
      position: "relative"
    }
  }, "Tags", React.createElement(_components.a, {
    href: "#tags",
    "aria-label": "tags permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/components/tag/"
  }, "Tags"), " provide useful metadata about the page. The relative importance of this data varies from page to page: the visual variations provided by Tags can help prioritize them in the header."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Page header={\n\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t<Stack.Item fill>\n\t\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t\t<Headline size=\"large\" className=\"m-b-0\">\n\t\t\t\t\tBatch #33: Plumbing Small Parts\n\t\t\t\t</Headline>\n\t\t\t\t<Tag color='info' compact>Scheduled</Tag>\n\t\t\t</Stack>\n\t\t</Stack.Item>\n\t</Stack>\n}>\n\t<Divider spacing=\"4\" />\n\tContent of the page.\n</Page>\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Page header={\n\t<>\n\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t<Stack.Item fill>\n\t\t\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t\t\t<Headline size=\"large\" className=\"m-b-0\">\n\t\t\t\t\t\tOld Equipment\n\t\t\t\t\t</Headline>\n\t\t\t\t</Stack>\n\t\t\t</Stack.Item>\n\t\t</Stack>\n\t\t<div>\n\t\t\t<TagGroup className=\"m-t-1 m-b-2\">\n\t\t\t\t<Tag color='success'>Active</Tag>\n\t\t\t\t<Tag subtle>Unsold Estimates</Tag>\n\t\t\t</TagGroup>\n\t\t\t<BodyText subdued>A standard description for the entire page.</BodyText>\n\t\t</div>\n\t</>\n}>\n\t<Divider spacing=\"4\" />\n\tContent of the page.\n</Page>\n")), "\n", React.createElement(_components.h2, {
    id: "actions",
    style: {
      position: "relative"
    }
  }, "Actions", React.createElement(_components.a, {
    href: "#actions",
    "aria-label": "actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Actions can refer to many different activities a user can perform at the page level."), "\n", React.createElement(_components.h3, {
    id: "types-of-actions",
    style: {
      position: "relative"
    }
  }, "Types of Actions", React.createElement(_components.a, {
    href: "#types-of-actions",
    "aria-label": "types of actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The majority of page-level actions should be located within the header. Some actions are more appropriate for the page footer. Actions that only apply to a section of the page, or tied to a Table, should"), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Add / Create"), "\n", React.createElement(_components.li, null, "Starting a Flow"), "\n", React.createElement(_components.li, null, "Edit page contents"), "\n", React.createElement(_components.li, null, "Switch to"), "\n", React.createElement(_components.li, null, "Secondary page actions, such as download, share, print, import, etc."), "\n", React.createElement(_components.li, null, "A generic action that brings the user to a new page, ", React.createElement(_components.a, {
    href: "/components/takeover/"
  }, "Takeover"), ", or ", React.createElement(_components.a, {
    href: "/components/modal/"
  }, "Modal"), "."), "\n", React.createElement(_components.li, null, React.createElement(_components.a, {
    href: "#search-and-filtering"
  }, "Search and filtering")), "\n"), "\n", React.createElement(_components.h4, {
    id: "actions-that-should-be-avoided-in-the-page-header",
    style: {
      position: "relative"
    }
  }, "Actions that should be avoided in the page header", React.createElement(_components.a, {
    href: "#actions-that-should-be-avoided-in-the-page-header",
    "aria-label": "actions that should be avoided in the page header permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The majority of these actions are located at the bottom of the page."), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Save"), "\n", React.createElement(_components.li, null, "Canceling a process"), "\n", React.createElement(_components.li, null, "Previous / Next functionality"), "\n", React.createElement(_components.li, null, "Ending / Finalizing a flow"), "\n", React.createElement(_components.li, null, "Actions that are explicitly tied to a ", React.createElement(_components.a, {
    href: "/components/form/"
  }, "Form")), "\n"), "\n", React.createElement(_components.h3, {
    id: "button-based-actions",
    style: {
      position: "relative"
    }
  }, "Button-based Actions", React.createElement(_components.a, {
    href: "#button-based-actions",
    "aria-label": "button based actions permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Most actions can be represented through ", React.createElement(_components.a, {
    href: "/components/Button"
  }, "Buttons"), ". These appear on the right side of the page. Except for the overflow menu, the most important actions are on the right-most side of the header. There should only be between 0-1 primary actions (visually a solid blue button) in the page header. Button actions should not be explicitly tied to a ", React.createElement(_components.a, {
    href: "/components/table"
  }, "Table"), "."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst actions = (\n\t\t<ButtonGroup>\n\t\t\t<Button small>View on Mobile</Button>\n\t\t\t<Button small primary>Create Rule</Button>\n\t\t</ButtonGroup>\n\t);\n\n\treturn (\n\t\t<Page header={\n\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t{title('Markups')}\n\t\t\t\t</Stack.Item>\n\t\t\t\t{actions}\n\t\t\t</Stack>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst actions = (\n\t\t<ButtonGroup>\n\t\t\t<Tooltip text='Print invoice'><Button small iconName='print' /></Tooltip>\n\t\t\t<Tooltip text='Email invoice'><Button small iconName='email' /></Tooltip>\n\t\t\t<Tooltip text='Edit invoice' direction='tl'><Button small iconName='edit' /></Tooltip>\n\t\t</ButtonGroup>\n\t);\n\n\treturn (\n\t\t<Page header={\n\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t{title('Invoice #123456')}\n\t\t\t\t</Stack.Item>\n\t\t\t\t{actions}\n\t\t\t</Stack>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h4, {
    id: "overflow-action-menu",
    style: {
      position: "relative"
    }
  }, "Overflow Action Menu", React.createElement(_components.a, {
    href: "#overflow-action-menu",
    "aria-label": "overflow action menu permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "An overflow menu is placed on the right-most side of the header. This menu is useful when there are many page-level actions, the number of potential overflow actions varies by context, and there is a need to purposefully deemphasize actions (e.g. delete). The disadvantage of an overflow menu is the lack of discoverability of the actions and the extra clicks it takes to complete an action."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst [open, setOpen] = React.useState(false);\n\tconst actions = (\n\t\t<ButtonGroup>\n\t\t\t<Button small>Clone</Button>\n\n\t\t\t<ActionMenu\n\t\t\t\topen={open}\n\t\t\t\ttrigger={<Button small iconName='more_vert' onClick={() => setOpen(!open)} selected={open} />}\n\t\t\t\tdirection=\"bl\"\n\t\t\t>\n\t\t\t\t<ActionMenu.Item>Edit</ActionMenu.Item>\n\t\t\t\t<ActionMenu.Item>Delete</ActionMenu.Item>\n\t\t\t</ActionMenu>\n\n\t\t</ButtonGroup>\n\t);\n\n\treturn (\n\t\t<Page header={\n\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t{title('Campaign: New Style HVAC')}\n\t\t\t\t</Stack.Item>\n\t\t\t\t{actions}\n\t\t\t</Stack>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h3, {
    id: "search-and-filtering",
    style: {
      position: "relative"
    }
  }, "Search and Filtering", React.createElement(_components.a, {
    href: "#search-and-filtering",
    "aria-label": "search and filtering permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Page-level search and filtering are separated from Button-based actions. When they manipulate data on the page, they appear below the description area, starting on the left. When they manipulate data across many pages, they are to the left of other persistent Button-based actions."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst actions = <Button small>Clone</Button>;\n\n\treturn (\n\t\t<Page header={\n\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t{title('Page Title')}\n\t\t\t\t</Stack.Item>\n\t\t\t\t{actions}\n\t\t\t</Stack>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\t<Form className=\"m-b-4\">\n\t\t\t\t<Form.Input placeholder='Search Content' icon=\"search\" iconPosition=\"left\" small style={{ width: '300px' }} />\n\t\t\t</Form>\n\t\t\t<BodyText>Rest of Page Content</BodyText>\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t</Stack>\n\t);\n\tconst actions = <Button small>Clone</Button>;\n\n\treturn (\n\t\t<Page header={\n\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t{title('Page Title')}\n\t\t\t\t</Stack.Item>\n\t\t\t\t{actions}\n\t\t\t</Stack>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\t<Form className=\"m-b-4\">\n\t\t\t\t<Form.Group>\n\t\t\t\t\t<Form.AnvilSelect label=\"Date Type\" icon=\"search\" iconPosition=\"left\" trigger={{ size: 'small', placeholder: 'Completion Date' }} options={[]} />\n\t\t\t\t\t<Form.AnvilSelect label=\"Date Range\" icon=\"search\" iconPosition=\"left\" trigger={{ size: 'small', placeholder: 'Last 30 Days' }} options={[]} />\n\t\t\t\t\t<Form.AnvilSelect label=\"Business Unit\" shortLabel=\"funnel\" trigger={{ size: 'small', placeholder: 'Business Unit' }} options={[]} />\n\t\t\t\t</Form.Group>\n\t\t\t</Form>\n\t\t\t<BodyText>Rest of Page Content</BodyText>\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h2, {
    id: "navigation",
    style: {
      position: "relative"
    }
  }, "Navigation", React.createElement(_components.a, {
    href: "#navigation",
    "aria-label": "navigation permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.h3, {
    id: "breadcrumbs",
    style: {
      position: "relative"
    }
  }, "Breadcrumbs", React.createElement(_components.a, {
    href: "#breadcrumbs",
    "aria-label": "breadcrumbs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/components/breadcrumb/"
  }, "Breadcrumbs"), " are an optional, supplemental navigational element that helps users understand where they are in the app. Breadcrumbs don’t show where a user has been, but shows the underlying site structure to a user. If a page can be accessed through multiple parent pages (i.e. poly-hierarchical), a single pathway should be designated. Assuming a page title exists, the page you are on should not be represented in the breadcrumbs."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst breadcrumbs = (\n\t\t<Breadcrumb className=\"m-b-1\">\n\t\t\t<Breadcrumb.Link label=\"Root page\" />\n\t\t\t<Breadcrumb.Link label=\"Subpage\" />\n\t\t\t<Breadcrumb.Link label=\"Current page\" />\n\t\t</Breadcrumb>\n\t);\n\tconst title = (text = 'Page Title') => <Headline size=\"large\" className=\"m-b-0\">{text}</Headline>;\n\tconst actions = <Button small>Action</Button>;\n\n\treturn (\n\t\t<Page header={\n\t\t\t<>\n\t\t\t\t{breadcrumbs}\n\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t{title('Page Title')}\n\t\t\t\t\t</Stack.Item>\n\t\t\t\t\t{actions}\n\t\t\t\t</Stack>\n\t\t\t</>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h4, {
    id: "when-to-use-breadcrumbs",
    style: {
      position: "relative"
    }
  }, "When to use Breadcrumbs", React.createElement(_components.a, {
    href: "#when-to-use-breadcrumbs",
    "aria-label": "when to use breadcrumbs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "If the page has a parent page (i.e. a page represented in the sidebar or global header)"), "\n", React.createElement(_components.li, null, "When a user has likely entered the page from outside the hierarchy"), "\n"), "\n", React.createElement(_components.h4, {
    id: "when-not-to-use-breadcrumbs",
    style: {
      position: "relative"
    }
  }, "When not to use Breadcrumbs", React.createElement(_components.a, {
    href: "#when-not-to-use-breadcrumbs",
    "aria-label": "when not to use breadcrumbs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "When there is no parent page to return to, such as if the page appears in a Sidebar."), "\n", React.createElement(_components.li, null, "Within a flow or wizard. The ", React.createElement(_components.a, {
    href: "/components/back-link/"
  }, "Back Link"), " can be used instead."), "\n", React.createElement(_components.li, null, "To replace any other navigational structure."), "\n"), "\n", React.createElement(_components.h3, {
    id: "tertiary-navigation-via-tabs",
    style: {
      position: "relative"
    }
  }, "Tertiary navigation via Tabs", React.createElement(_components.a, {
    href: "#tertiary-navigation-via-tabs",
    "aria-label": "tertiary navigation via tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "A tertiary navigation (primary navigation being the global header, secondary being the ", React.createElement(_components.a, {
    href: "/components/sidebar/"
  }, "sidebar"), ") can be added to the Page header. This is represented through the ", React.createElement(_components.a, {
    href: "/components/tab/"
  }, "Tab"), " component. Tabs in the header can be divided into two purposes: to navigate to different pages related to the ", React.createElement(_components.a, {
    href: "#page-title"
  }, "title"), ", and to filter content on the page, such as a ", React.createElement(_components.a, {
    href: "/components/visualization/table/"
  }, "table"), "."), "\n", React.createElement(_components.h4, {
    id: "page-level-tabs",
    style: {
      position: "relative"
    }
  }, "Page-level Tabs", React.createElement(_components.a, {
    href: "#page-level-tabs",
    "aria-label": "page level tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "Use page-level Tabs when the header content above the Tabs does not change between tab switches. Types of content below the page typically varies between tabs."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst title = (text = 'Page Title') => <Headline size=\"large\" className=\"m-b-0\">{text}</Headline>;\n\n\treturn (\n\t\t<Page header={\n\t\t\t<>\n\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t{title('Sync Log')}\n\t\t\t\t\t</Stack.Item>\n\t\t\t\t</Stack>\n\t\t\t\t<TabGroup className=\"m-t-3\">\n\t\t\t\t\t<Tab active>Services</Tab>\n\t\t\t\t\t<Tab>Material</Tab>\n\t\t\t\t\t<Tab>Equipment</Tab>\n\t\t\t\t</TabGroup>\n\t\t\t</>\n\t\t}>\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h2, {
    id: "notifications",
    style: {
      position: "relative"
    }
  }, "Notifications", React.createElement(_components.a, {
    href: "#notifications",
    "aria-label": "notifications permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, React.createElement(_components.a, {
    href: "/components/banner/"
  }, "Banner"), " notifications should appear at the bottom of the page header, above the start of the page content."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\n---\nconst Example = () => {\n\tconst breadcrumbs = (\n\t\t<Breadcrumb className=\"m-b-1\">\n\t\t\t<Breadcrumb.Link label=\"Root page\" />\n\t\t\t<Breadcrumb.Link label=\"Subpage\" />\n\t\t\t<Breadcrumb.Link label=\"Current page\" />\n\t\t</Breadcrumb>\n\t);\n\tconst title = (text = 'Page Title') => (\n\t\t<Stack alignItems=\"center\" spacing={1}>\n\t\t\t<Headline size=\"large\" className=\"m-b-0\">{text}</Headline>\n\t\t\t<Tag>Tag</Tag>\n\t\t</Stack>\n\t);\n\tconst actions = (\n\t\t<ButtonGroup>\n\t\t\t<Button small>Action</Button>\n\t\t\t<Button primary small>Primary Action</Button>\n\t\t</ButtonGroup>\n\t);\n\tconst description = (\n\t\t<BodyText subdued className=\"m-t-1\">A standard description for the entire page.</BodyText>\n\t);\n\n\treturn (\n\t\t<Page header={\n\t\t\t<>\n\t\t\t\t{breadcrumbs}\n\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t{title('Page Title')}\n\t\t\t\t\t</Stack.Item>\n\t\t\t\t\t{actions}\n\t\t\t\t</Stack>\n\t\t\t\t{description}\n\t\t\t</>\n\t\t}>\n\t\t\t<Divider spacing=\"4\" />\n\t\t\t<Banner\n\t\t\t\ticon\n\t\t\t\ttitle=\"You are viewing an inactive item.\"\n\t\t\t\tclassName=\"m-b-4\"\n\t\t\t/>\n\t\t\tContent of the page.\n\t\t</Page>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h1, {
    id: "boxed-style-headers",
    style: {
      position: "relative"
    }
  }, "Boxed Style Headers", React.createElement(_components.a, {
    href: "#boxed-style-headers",
    "aria-label": "boxed style headers permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The header can be customized to be \"boxed\" from the content below it. It applies a full-width white background. This styling can be used when incorporating a custom fixed header style, or when the page is very dense in content. When using, it is recommended to consistently use it across the same section of the app. Caution should be used when using this style, as it is visually heavier, and is a little more difficult to technically maintain."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "customRender: true\nscaled: true\n---\nconst Example = () => {\n\tconst breadcrumbs = (\n\t\t<Breadcrumb className=\"m-b-1\">\n\t\t\t<Breadcrumb.Link label=\"Root page\" />\n\t\t\t<Breadcrumb.Link label=\"Subpage\" />\n\t\t\t<Breadcrumb.Link label=\"Current page\" />\n\t\t</Breadcrumb>\n\t);\n\tconst title = (text = 'Page Title') => <Headline size=\"large\" className=\"m-b-0\">{text}</Headline>;\n\tconst actions = <Button primary small>Primary Action</Button>;\n\tconst description = <BodyText subdued className=\"m-t-1 m-b-3\">A standard description for the entire page.</BodyText>;\n\n\treturn (\n\t\t<Frame\n\t\t\t/* Frame's header prop is a visual demo. */\n\t\t\theader={<div style={{ height: 56 }}><div style={{backgroundColor: \"#141414\",height: 56,position: \"fixed\",top: 0,width: \"100%\",zIndex: 1}}/></div>}\n\t\t>\n\t\t\t<Page\n\t\t\t\tsidebar={<Sidebar localStorageKey=\"page-headers__boxed-style-headers\" />}\n\t\t\t\tspacing='none'\n\t\t\t\tmaxWidth='wide'\n\t\t\t\theader={\n\t\t\t\t\t<div className=\"bg-white p-t-3\" style={{ borderBottom: '1px solid #dfe0e1' }}>\n\t\t\t\t\t\t<div className=\"m-x-auto p-x-5\" style={{ maxWidth: '1280px' }}>\n\t\t\t\t\t\t\t{breadcrumbs}\n\t\t\t\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t\t\t\t{title('Page Title')}\n\t\t\t\t\t\t\t\t</Stack.Item>\n\t\t\t\t\t\t\t\t{actions}\n\t\t\t\t\t\t\t</Stack>\n\t\t\t\t\t\t\t{description}\n\t\t\t\t\t\t</div>\n\t\t\t\t\t</div>\n\t\t\t\t}\n\t\t\t>\n\t\t\t\t<div className=\"m-x-auto p-b-3 p-x-5 p-y-2\" style={{ maxWidth: '1280px' }}>\n\t\t\t\t\tContent of the page.\n\t\t\t\t</div>\n\t\t\t</Page>\n\t\t</Frame>\n\t);\n}\nrender (<Example />)\n")), "\n", React.createElement(_components.h3, {
    id: "example-with-tabs",
    style: {
      position: "relative"
    }
  }, "Example with Tabs", React.createElement(_components.a, {
    href: "#example-with-tabs",
    "aria-label": "example with tabs permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.p, null, "The box styling can be paired well with Tabs. This use case works well when Tabs act as navigation between different types of page content."), "\n", React.createElement(_components.pre, null, React.createElement(_components.code, {
    className: "language-jsx"
  }, "<Page\n\tspacing='none'\n\tmaxWidth='wide'\n\theader={\n\t\t<div className=\"bg-white p-t-3\" style={{ borderBottom: '1px solid #dfe0e1' }}>\n\t\t\t<div className=\"m-x-auto p-x-5\" style={{ maxWidth: '1280px' }}>\n\t\t\t\t<Stack alignItems=\"center\" spacing={2} wrap='wrap'>\n\t\t\t\t\t<Stack.Item fill>\n\t\t\t\t\t\t<Headline size=\"large\" className=\"m-b-0\">Page Title</Headline>\n\t\t\t\t\t</Stack.Item>\n\t\t\t\t</Stack>\n\t\t\t\t<TabGroup className=\"m-t-3\" divider={false}>\n\t\t\t\t\t<Tab active>Ready To Bill <Tag compact badge>127</Tag></Tab>\n\t\t\t\t\t<Tab>Action Required <Tag compact badge color=\"critical\">25</Tag></Tab>\n\t\t\t\t\t<Tab>Insights</Tab>\n\t\t\t\t\t<Tab>Settings</Tab>\n\t\t\t\t</TabGroup>\n\t\t\t</div>\n\t\t</div>\n\t}\n>\n\t<div className=\"m-x-auto p-b-3 p-x-5\" style={{ maxWidth: '1280px' }}>\n\t\tContent of the page.\n\t</div>\n</Page>\n")), "\n", React.createElement(_components.hr), "\n", React.createElement(_components.h1, {
    id: "best-practices",
    style: {
      position: "relative"
    }
  }, "Best Practices", React.createElement(_components.a, {
    href: "#best-practices",
    "aria-label": "best practices permalink",
    className: "anchor after"
  }, React.createElement(_components.div, {
    dangerouslySetInnerHTML: {
      __html: "<svg aria-hidden=\"true\" focusable=\"false\" height=\"16\" version=\"1.1\" viewBox=\"0 0 16 16\" width=\"16\"><path fill-rule=\"evenodd\" d=\"M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z\"></path></svg>"
    }
  }))), "\n", React.createElement(_components.ul, null, "\n", React.createElement(_components.li, null, "Almost all pages should at least have a title."), "\n", React.createElement(_components.li, null, "Page Headers should be paired with ", React.createElement(_components.a, {
    href: "/patterns/page-layout/"
  }, "Page Layout"), "."), "\n"));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
